.right {
  padding: 0 !important;
}

.box img {
  max-height: 80%;
}
.back-button svg {
  height: 16px;
}

.iframe {
  width: 320px;
  height: 600px;
  border-radius: 1rem;
}

.right-text-heading {
  font-size: 14px;
  font-weight: bold;
  margin: 8px 0;
}

@media screen and (max-width: 768px) {
  .iframe {
    width: 100vw;
    height: 100vh;
    border: none;
    border-radius: 0;
    position: fixed;
    top: 0;
    /* position: fixed; */
    /* z-index: -1; */
  }
}
