#tabs {
  svg{
    height:26px
  }
}

.tab-text {
  font-size: 18px;
}

@media screen and (max-width: 768px) {
  .custom-width {
    width: calc(100% - 20px);
  }
  .tab-text {
    font-size: 12px;
  }
}

.heading-text {
  background-color: #fff;
  padding-left: 48px;
  span {
    color: rgba(255,134,19,1);
  }
  &::before {
    content: '';
    background-color: rgba(255, 134, 19, 1);
    width: 14px;
    height: 32px;
    position: absolute;
    left: 24px;
    transform: translateY(-50%);
    top: 50%;
  }
}
