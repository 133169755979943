.button:hover {
  display: block;
}
.button {
  display: block;
}
.icon {
  img {
    width: 45px;
    height: 45px;
  }
}
.ad_image_container {
  width: 17rem;
}
.ad_image_container:hover {
  border: 2px solid orange;
}
