body {
  color: var(--foreground);
  background: var(--background);
  font-family: "Inter", sans-serif;
  background-color: white;
}
.company-logo {
  display: flex;
  align-items: center;
  margin-right: 36px;
  font-size: 22px;
  font-weight: 600;
  line-height: 33px;
}
.dropdown-menu {
  background-color: #fff;
  border-radius: 18px;
  padding: 12px 20px;
  position: absolute;
  z-index: 9;
  top: 60px;
  left: 0;
  box-shadow: 0 8px 20px rgba(208, 208, 196, 0.32);
  white-space: nowrap;
  color: #1a1a1a;
  li {
    padding: 4px;
  }
}
.description {
  font-size: 15px;
}
.arrow {
  margin-left: 8px;
  svg {
    width: 10px;
    height: 20px;
    display: inline-block;
  }
}
.nav-item {
  &:not(.dropdown-menu li a):hover {
    color: #ff7722;
  }
}
.nav-item .dropdown-menu {
  display: none;
  li {
    &:hover {
      a {
        color: #ff7722;
      }
    }
  }
}
.nav-item:hover .dropdown-menu {
  display: block;
}

.login-btn {
  font-size: 14px;
}

.text-list {
  li {
    a {
      font-size: 1rem;
      line-height: 1.75rem;
      color: #434a57;
      font-weight: 500;
      &:hover {
        color: #ff7722;
      }
    }
  }
}

.subscrib-box {
  border: 1px solid #d7dde2;
  padding: 12px 8px;
  border-radius: 12px;
  white-space: nowrap;
}
.form-control {
  width: calc(100% - 90px);
  border-color: rgba(0, 0, 0, 0) !important;
  border-radius: 0 !important;
  outline: none;
  padding: 4px 8px;
  white-space: nowrap;
}

.btn-subscribe {
  background-color: #ff7722;
  color: #fff;
  font-size: 12px;
  line-height: 20px;
  border-radius: 12px;
  padding: 10px 20px;
}

.footer-bottom {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  .text {
    line-height: 20px;
    color: #858c97;
  }
  .link {
    font-weight: 400;
    margin-left: 4px;
    color: #434a57;
  }
}

.image {
  width: 286px;
  height: 494px;
  max-width: 335px;
  position: relative;
  left: 2px;
  top: -11px;
}

.page-layout {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 75px;
  .left {
    flex: 1;
  }
  .right {
    flex: 0 0 240px;
    border-left: 10px solid rgba(0, 0, 0, 0.04);
    overflow: auto;
    padding: 20px;
    height: 100vh;
    .box {
      width: 100%;
      height: 100%;
      margin-bottom: 12px;
      margin-top: 12px;
      border-radius: 24px;
      border: 4px solid white;
      box-sizing: border-box;
      padding-bottom: 30px;
      max-height: 300px;
    }
  }
}

@media screen and (max-width: 768px) {
  .mob-nav {
    position: absolute;
    right: 0;
    top: 100%;
    left: 50%;
    width: calc(100% - 30px);
    transform: translateX(-50%);
    box-sizing: border-box;
    flex-flow: column;
    display: none;
    padding: 12px 20px;
    font-size: 16px;
    background: #fff;
    border-radius: 12px;
    border: 1px solid #ededed;
    opacity: 0;
    /* width: 100%; */
    /* margin: 0 16px; */
    .mob-nav-list {
      flex-flow: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
  .nav-bar-icon {
    height: 32px;
    width: 30px;
    cursor: pointer;
    position: absolute;
    right: 12px;
  }

  .nav-bar-icon span::before,
  .nav-bar-icon span::after {
    width: 15.3px;
  }

  .nav-bar-icon span {
    width: 22px;
  }

  .nav-bar-icon span,
  .nav-bar-icon span::before,
  .nav-bar-icon span::after {
    background: #434a57;
    border-radius: 3px;
    content: "";
    position: absolute;
    /* width: 22px; */
    height: 2px;
    margin-top: 9px;
    transition: 0.3s ease-in-out;
    left: 0;
    top: 0;
  }
  .nav-bar-icon span::after {
    left: auto;
    right: 0;
  }

  .nav-bar-icon span::before {
    margin-top: -8px;
  }

  .nav-bar-icon span::after {
    margin-top: 8px;
  }

  .nav-bar-icon.active span {
    background: transparent;
    width: 22px;
  }

  .nav-bar-icon.active span::before {
    margin-top: 0;
    transform: rotate(45deg);
    width: 22px;
  }

  .nav-bar-icon.active span::after {
    margin-top: 0;
    transform: rotate(-45deg);
    width: 22px;
  }
  .nav-bar-icon.active ~ nav {
    display: block;
    opacity: 1;
  }
}

.section_heading {
  top: 88px;
}

@media screen and (max-width: 1024px) {
  .section_heading {
    top: 67px;
  }
}

.brand-title {
  font-size: 40px;
}
